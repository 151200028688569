<template>
  <section class="banner-slider-block">
    <SliderSectionSwiperWrapper
      class="banner-slide-wrapper"
      :title="title"
      :link="{ name: cta.label, slug: cta.link }"
      :is-points="isPoints"
      :slider-props="{
        slidesPerView: 1.15,
        spaceBetween: 16,
        slidesOffsetBefore: 7,
        slidesOffsetAfter: 7,
        breakpoints: {
          [BREAKPOINTS.mobile]: {
            slidesPerView: 'auto',
            spaceBetween: 16,
            slidesOffsetBefore: 64,
            slidesOffsetAfter: 64
          }
        }
      }"
    >
      <template #swiper-elements>
        <SwiperSlide v-for="article of articles" :key="article.id" class="swiper-element">
          <EditoCard
            :title="article.attributes.title"
            :subtitle="article.attributes.tags?.data[0]?.attributes.name"
            :type="'article'"
            :geographic-zone-slug="
              article.attributes.geographicZones?.data[0]?.attributes.slug ?? ''
            "
            :country-slugs="
              article.attributes.countries?.data.map(data => data.attributes?.slug) ?? []
            "
            :description="article.attributes.description"
            :image="
              article.attributes.image?.data?.attributes.url ??
              '/uploads/placeholder_5109e12df7.svg'
            "
            :link="`/autour-du-voyage/carnet-de-voyage/${article.attributes.tags?.data[0]?.attributes.slug}/${article.attributes.slug}`"
            :continent="article.attributes.geographicZones.data[0]"
            class="article"
          />
        </SwiperSlide>
      </template>
    </SliderSectionSwiperWrapper>
    <NuxtLink class="link" :href="cta.link">{{ cta.label }}</NuxtLink>
  </section>
</template>

<script lang="ts" setup>
import { BREAKPOINTS } from '@/lib/variables';

import { Article } from '@/lib/strapi-types/Article';
defineProps<{
  title: string;
  articles: Article[];
  isPoints?: boolean;
}>();

const cta = {
  label: 'Voir tous nos carnets de voyage',
  link: '/autour-du-voyage/carnet-de-voyage'
};
</script>

<style scoped lang="scss">
@use '$/spacings.scss';
@use '$/breakpoints.scss';
@use '$/typography.scss';
@use '$/button.scss';
@use '$/misc.scss';

.banner-slider-block {
  flex-direction: column;
  margin-bottom: spacings.$desktop-vertical-min;

  .link {
    display: none;
  }

  .banner-slide-wrapper {
    .swiper-element {
      width: 310px;

      :deep(.edito-card) {
        .image {
          height: 220px;
          min-height: 220px;
        }
      }
    }
  }
}

@include breakpoints.mobile() {
  .banner-slider-block {
    margin-bottom: 24px;

    .link {
      @include misc.bold-link();

      display: block;
      grid-column: 1 / 3;
      align-self: center;
      margin-top: 0;
    }

    .banner-slide-wrapper {
      .swiper-element {
        padding: 0 spacings.$mobile-horizontal;

        .article {
          :deep(.edito-card) {
            max-width: 100%;
          }
        }

        &:last-child {
          padding-right: spacings.$mobile-horizontal;
        }
      }

      :deep(.title-header) {
        margin-bottom: unset;

        .right {
          display: none;
        }
      }

      :deep(.swiper-slide) {
        padding: 0 4px;
      }

      :deep(.content) {
        padding: 24px 14px;

        .wrapper-content {
          .title {
            font-size: typography.$font-size-lg;
          }

          .subtitle {
            font-size: typography.$font-size-md;
            font-weight: 600;
          }

          .description {
            font-size: typography.$font-size-md;
            font-weight: 400;
          }
        }

        .wrapper-cta {
          flex: none;
        }
      }

      :deep(.note-book-card) {
        width: 100%;
      }
    }
  }
}
</style>
